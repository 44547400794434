import styled, { useTheme } from 'styled-components';

import Section from '@/components/section';

const backgroundColor = ({ $background, theme, primary }) => {
  if ($background) {
    return $background;
  }

  if (primary) {
    return '#4b4df1';
  }

  return theme.colors.background.accent;
};

const fontColor = ({ theme, primary }) => {
  if (primary) {
    return 'white';
  }

  return theme.colors.typography.primary;
};

const TaglineContainer = styled.div`
  background-color: ${backgroundColor};
  padding: 2em;
`;

const TagLine = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  color: ${fontColor};
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Banner = ({ background, children, light, primary, overflowHidden, style, taglineStyle }) => {
  const theme = useTheme();

  return (
    <Section background={backgroundColor({ background, primary, theme })} overflowHidden={overflowHidden} style={style}>
      <TaglineContainer $background={background} $light={light} primary={primary} style={taglineStyle}>
        <TagLine $background={background} $light={light} primary={primary}>
          {children}
        </TagLine>
      </TaglineContainer>
    </Section>
  );
};

export default Banner;
