import styled from 'styled-components';
import Image from 'next/image';
import { Tiles } from '@rebass/layout';
import { useTranslations } from 'next-intl';

import Button from '@/components/button';
import Section from '@/components/section';
import Phone from '@/components/phone';
import { breakpoints, media } from '@/themes';

const Layout = styled(Tiles)``;

const Title = styled.h1`
  margin: 0 0 .5em;

  ${media.sm`
    font-size: 1.5em;
  `};
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  position: relative;
  text-align: center;

  .phone {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: inset(-2em -2em 0 -2em);
    margin-top: -3em;
    padding-bottom: 0;
    transform: translateY(1px);

    ${media.md`
      font-size: .85em;
      max-width: 50vw;
      transform: translateY(.25em);
    `};

    ${media.sm`
      margin-top: 0;
    `};

    > span {
      display: block !important;
    }

    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const LeftColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 1em 0 0;
  text-align: left;

  ${media.sm`
    display: block;
    text-align: center;
  `};
`;

const Screenshot = styled(Phone)`
  max-width: 375px;
  margin: auto;
`;

const AppBanner = ({ background, source, inverted }) => {
  const t = useTranslations('appBanner');
  let downloadUrl = 'https://www.bidali.com/app?utm_campaign=crypto_customer_remarketing&utm_medium=giftcards_site&utm_source=giftcard_site&utm_content=app_download_cta';

  if (source) {
    downloadUrl = `${downloadUrl}_${source}`;
  }

  return (
    <Section background={background} style={{ padding: 0 }} inverted={inverted}>
      <InnerContainer>
        <Layout columns={[1, 1, 2]}>
          <LeftColumn>
            <Title>{t('title')}</Title>

            <p>{t.rich('description', { strong: children => <strong>{children}</strong> })}</p>
            <a target="_blank" href={downloadUrl} title={t('linkTitle')} rel="noopener noreferrer">
              <Button accent={inverted} primary={!inverted}>{t('cta')}</Button>
            </a>
          </LeftColumn>
          <RightColumn>
            <Screenshot isDark>
              <Image
                height={380}
                src="/images/app-screenshot.jpg"
                unoptimized
                width={295}
                alt="Bidali mobie app screenshot"
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
            </Screenshot>
          </RightColumn>
        </Layout>
      </InnerContainer>
    </Section>
  );
};

export default AppBanner;
